import React, { useRef, useEffect } from "react";
import Helmet from 'react-helmet';
import { graphql } from 'gatsby'

// animations
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { animSetting } from "@hooks/animations";

import "@assets/scss/App.scss";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "@components/layout";
import Header from "@components/header/Header";
import ContentHeader from "@components/modules/content-header/";
import BlogBox from "@components/blog-box/";
import SelectCat from "@components/modules/select-filter-cat/index_2.js";
import FormSubscribe from "@components/forms/subscribe-form/subscribe-form";
import PopularSearch from "@components/popular-search/index";
import Footer from "@components/footer/";
import SEO from "@components/seo";
import FloatingBtn from "@components/floating-book-btn/";

// Force plugin to not get dropped during build
gsap.registerPlugin(ScrollTrigger);

const AgencyMentors = ({ data, location }, props) => {
    let selectAnim = useRef(null);
    let boxAnim = useRef([]);
    boxAnim.current = [];
    let contAnim = useRef([]);

    const addToRefs = el => {
        if (el && !boxAnim.current.includes(el)) {
            boxAnim.current.push(el);
        }
    };

    useEffect(() => {
        const tl = gsap.timeline({
            defaults: {
                duration: animSetting.duration,
                ease: animSetting.ease,
            }
        });
        tl.fromTo(selectAnim, {
            opacity: 0,
            x: 100,
            duration: 0.8
        },{
            opacity: 1,
            x: 0,
            delay: 0.8
        });

        gsap.fromTo(boxAnim.current, {
            y: -70,
            autoAlpha: 0
        },{
            y: 0,
            autoAlpha: 1,
            duration: 0.6,
            stagger: 0.4,
            scrollTrigger: {
                trigger: contAnim,
                start: 'top 70%',
                end: 'bottom bottom',
                toggleActions: 'play none none reverse',
                ease: animSetting.ease
            }
        })

        // const tlBox = gsap.timeline({
        //     defaults: {
        //         duration: animSetting.duration,
        //         ease: animSetting.ease,
        //     },
        //     scrollTrigger: {
        //         trigger: contAnim,
        //         start: 'top 70%',
        //         end: 'bottom bottom',
        //         toggleActions: 'play none none reverse',
        //         ease: animSetting.ease
        //     }
        // })
        // tlBox.addLabel('start')
        // .fromTo(boxAnim.current, {
        //     opacity: 0,
        //     y: 70
        // },{
        //     opacity: 1,
        //     y: 0,
        //     stagger: 0.5
        // })
    }, [])

    //console.log("allStrapiCategories", data.allStrapiCategories.edges, location);
    return(
        <Layout>
                <SEO
                    title="Agency Mentors"
                    description=""
                />
                <Helmet 
                    bodyAttributes={{
                        class: 'white-bg'
                    }}
                />
                <Header />
                <Container>
                    <Row>
                        <Col xs={12}>
                            <Row className="mt-33 mb-29 mt-md-40 mt-xl-80 mb-xl-77 d-flex align-items-end">
                                <Col xs={12} lg={8}>
                                    <ContentHeader
                                        sectionContainerClass=""
                                        Title="All the latest Insights, Resources and Advice on Estate Agent and PropTech."
                                        titleClass=" mb-29 mb-lg-0"
                                        pageName_1="Agency Mentors"
                                        pageLink_1="#"
                                        lastClass_1="last"
                                    />
                                </Col>
                                <Col xs={12} lg={{ span: 3, offset: 1 }}>
                                    <div ref={el => selectAnim = el}>
                                        <SelectCat
                                            selectOptions={data.allStrapiCategories.edges}
                                            location={location}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
                <section className={`section mb-6 mb-md-0 mb-lg-44`} ref={el => contAnim = el}>
                    <Container>
                        <Row>
                            {data.allStrapiAgencyMentors.edges.map((box) => {
                                return (
                                    <BlogBox
                                        key={box.node.id}
                                        slug={`/agency-mentors/${box.node.category && box.node.category.Slug}/${box.node.Slug}`}
                                        boxClass=" pb-22 pb-md-52 pb-lg-55 col-md-6 col-lg-4"
                                        imgUrl={box.node.BannerImage.Image?.internal?.description}
                                        categorylabelName={box.node.category ? box.node.category.labelName : ''}
                                        categoryLabelColour={box.node.category ? box.node.category.labelColour : ''}
                                        categoryLabelFontColour={box.node.category ? box.node.category.labelFontColour : ''}
                                        Title={box.node.Title}
                                        Text={box.node.articleText ? box.node.articleText.articleText : null}
                                        innerRef={addToRefs}
                                    />
                                )
                            })}
                        </Row>
                    </Container>
                </section>
                <FormSubscribe />
                <PopularSearch {...props}
                    sectionClass=" section-grey-bg"
                />
                <Footer />
                <FloatingBtn />
        </Layout>
    )
}

export const query = graphql`
  {
    allStrapiAgencyMentors(filter: {Published: {eq: true}}) {
        edges {
            node {
                id
                Slug
                Title
                articleText {
                    articleText
                }
                BannerImage {
                    Image {
                        internal {
                            description
                        }
                        childImageSharp {
                            id
                            fluid(quality: 90, maxWidth: 400, maxHeight: 240, cropFocus: CENTER, fit: COVER, background: "#fff") {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                category {
                    name
                    labelColour
                    labelFontColour
                    labelName
                    Slug
                }
            }
        }
    }
    allStrapiCategories(filter: {Published: {eq: true}, category: {Slug: {eq: "agency-mentors"}}}) {
        edges {
            node {
                id
                Slug
                category {
                    Slug
                }
                labelName
            }
        }
    }
}
`
export default AgencyMentors
